/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Main Shell */
button.p-button {
    background-color: #3B82F6 !important;
}
button.p-button.p-button-danger {
    background-color: #EF4444 !important;
}

